import React from 'react'
import Footer from '../componenets/FooterSearch/Footer'
import Profile from '../componenets/Profile/Profile'

export default function About(props) {
  return (
    <>
        {/* header */}
        <Profile searchCount={props.searchCount} setSearchCount={props.setSearchCount}/>
        <section className="about-tnc-privacy">
            <h1 className='py-5' style={{textAlign: 'center'}}>Terms and Conditions</h1>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Naturecallz by EightSphere LTD</p>
                        <p>These Terms and conditions ("Terms") are a legal binding contract between you, the user that utilizing EightSphere LTD's Services and Technology (referenced below as "You" or the "User"), and EightSphere LTD, (referenced below as &ldquo;EightSphere LTD&rdquo;, "us" or &ldquo;we&rdquo; or &ldquo;Company&rdquo;). EightSphere LTD is the legal owner of the Product and Technology, developed or otherwise licensed to us. These Terms constitute the terms and conditions by which the User allowed to use EightSphere LTD's Services and Technology, including associated features.</p>
                        <p>By accessing this Website or Using Services/Apps/Software, accessible from EightSphere.com, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site nor downloading and using the apps/services developed by the Company (&ldquo;EightSphere LTD&rdquo;). The materials contained in this Website are protected by copyright and trade mark law.</p>
                        <p>Accepting the terms can be in two ways:</p>
                        <ol>
                        <li>Accept by using the services. In this case you agree and understand the Company will consider it as Your acceptance of this terms and conditions. Or</li>
                        <li>Accept by action &ndash; Click on an acceptance button/checkbox or similar function. In this case you agree and understand the Company will consider it as Your acceptance of this terms and conditions<span class="Apple-converted-space">&nbsp;</span></li>
                        </ol>
                        <h3>1. Naturecallz and users relationship</h3>
                        <p>Your use of EightSphere&rsquo;s services is bound to this terms and conditions only, unless specified otherwise in an outer written agreement between You and the Company.</p>
                        <p>Some of the services are based on open source license agreements available at <a href="https://chromium.googlesource.com/chromium/src/+/master/LICENSE">https://chromium.googlesource.com/chromium/src/+/master/LICENSE</a>.</p>
                        <p>By reading these terms you agree to be bound to any third party open source software we might use on our products. EightSphere disclaims any/all liability to You or any third party related to such open source software.</p>
                        <p>While using our services through the Internet You are aware you might encounter the following:</p>
                        <ol>
                        <li>Viruses</li>
                        <li>Worms</li>
                        <li>Hacking</li>
                        <li>Ransom hacking</li>
                        <li>Trojan horses</li>
                        <li>Or any technological harassment</li>
                        </ol>
                        <p>&nbsp;</p>
                        <p>Such cases may harm and tamper Your computer (and Your data). EightSphere disclaims any liability to damages might occur during using our services.</p>
                        <p>You aware and acknowledge that, as with most Internet applications, there are certain risks associated with accepting or downloading files from or through EightSphere services. Such risks include but are not limited to (1) damage to Your computer, (2) damage to any data or files stored on Your computer, (3) EightSphere users viewing Your IP address, and (4) files You share with other EightSphere users being redistributed and used without Your knowledge.</p>
                        <p>Using EightSphere services is at your own full risk.</p>
                        <p>EightSphere is not obliged to update its services/products nor fixing any bugs.</p>
                        <h3>1.1 Using EightSphere&rsquo;s Services</h3>
                        <p>You are not allowed to interfere the Company services from working properly.</p>
                        <p>Your use of EightSphere&rsquo;s services will be limited solely to the purposes mentioned in this terms and conditions manifest.</p>
                        <h3>2. Use License</h3>
                        <p>Permission is granted to temporarily download one copy of the materials on EightSphere.com website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>
                        <p>modify or copy the materials; use the materials for any commercial purpose or for any public display; attempt to reverse engineer any software contained on&nbsp;EightSphere<span class="Apple-converted-space">&nbsp; </span>LTDs Website; remove any copyright or other proprietary notations from the materials; or transferring the materials to another person or "mirror" the materials on any other server.</p>
                        <p>This will let&nbsp;EightSphere LTD&nbsp;terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format.</p>
                        <h3>3. Disclaimer</h3>
                        <p>All the materials on&nbsp;EightSphere LTD&rsquo;s (&ldquo;the Company&rdquo;) Website/Services are provided "as is".&nbsp;EightSphere LTD&nbsp;makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore,&nbsp;EightSphere LTD&nbsp;does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.</p>
                        <p>The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</p>
                        <p>Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</p>
                        <p>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.</p>
                        <h3>4. Limitations</h3>
                        <p>EightSphere LTD&nbsp;or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on&nbsp;EightSphere LTD&rsquo;s Website/Services, even if&nbsp;EightSphere LTD&nbsp;or an authorize representative of this Website/Services has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.</p>
                        <h3>5. Revisions and Errata</h3>
                        <p>The materials appearing on&nbsp;EightSphere LTD&rsquo;s Website may include technical, typographical, or photographic errors.&nbsp;EightSphere LTD&nbsp;will not promise that any of the materials in this Website are accurate, complete, or current.&nbsp;EightSphere LTD&nbsp;may change the materials contained on its Website at any time without notice.&nbsp;EightSphere LTD&nbsp;does not make any commitment to update the materials.</p>
                        <h3>6. Links</h3>
                        <p>EightSphere LTD&nbsp;has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by&nbsp;EightSphere LTD&nbsp;of the site. The use of any linked website is at the user&rsquo;s own risk.</p>
                        <h3>7. Site Terms of Use EightSphere LTD</h3>
                        <p>EightSphere LTD&nbsp;may revise these Terms of Use for its Website/Services at any time without prior notice. By using this Website and Services, you are agreeing to be bound by the current version of these Terms and Conditions of Use.</p>
                        <h3>8. Your Privacy</h3>
                        <p>By using the Services, You authorize EightSphere to collect, process, use and store Your personal information that You provide to us in accordance with our current Privacy Policy</p>
                        <p>Please read our <a href="https://www.naturecallz.com/privacy-policy">Privacy Policy</a>.</p>
                        <p>By reading the Privacy Policy you agree to the use of your data.</p>
                        <h3>9. Governing Law</h3>
                        <p>Any claim related to&nbsp;EightSphere LTD's Website/Services shall be governed by the laws of State of Israel without regards to its conflict of law provisions.</p>
                        <h3>10. European Users</h3>
                        <p>EightSphere users in the European Union, by using all or any portion of the Services, understand, acknowledge and agree to the processing of your personal information in the United States.</p>
                        <h3>11. Contact information</h3>
                        <p>You may contact us at <a href="mailto:support@naturecallz.com">support@naturecallz.com</a> for any other questions or queries arise.</p>
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </section>

        {/* footer */}
        <Footer />
    </>
  )
}
