import React from 'react'
import Footer from '../componenets/FooterSearch/Footer'
import Profile from '../componenets/Profile/Profile'

export default function About(props) {
  return (
    <>
        {/* header */}
        <Profile searchCount={props.searchCount} setSearchCount={props.setSearchCount}/>
        <section className="about-tnc-privacy">
            <h1 className='py-5' style={{textAlign: 'center'}}>Privacy Policy</h1>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                    <p>At Naturecallz, accessible from&nbsp;Naturecallz.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that may be collected and recorded by&nbsp;EightSphere LTD (&ldquo;EightSphere&rdquo;)&nbsp;and how we use it.</p>
                    <p>References to "you" or &ldquo;user&rdquo; in this Agreement means you, your duly authorized representatives and any entity you may represent in connection with your use of the Services.</p>
                    <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us:</p>
                    <p>support@naturecallz.com</p>
                    <div>EightSphere LTD <br />7 Sapir Street <br />Hertzliya 4685211<br />IL <br />Phone Number: +972 723975627</div>
                    <div>&nbsp;</div>
                    <p>This Privacy Policy applies only to our online activities/services and is valid for users of our website/services with regards to the information that they shared and/or collect in&nbsp;Naturecallz. This policy is not applicable to any information collected offline or via channels other than this website.&nbsp;Any information collected, obtained and/or used by any such third parties is subject to the privacy policies of those third parties. We encourage you to review the policies of such third parties.</p>
                    <h3>Services Included in this Privacy Policy</h3>
                    <ol>
                    <li>Software on this website (the &ldquo;website&rdquo;).</li>
                    <li>Applications</li>
                    <li>Extensions</li>
                    <li>Programs</li>
                    <li>Software</li>
                    <li>Tools and Database</li>
                    </ol>
                    <h3>Consent</h3>
                    <p>By using our website and services, you hereby consent to our Privacy Policy and agree to its terms.</p>
                    <h3>Information we collect</h3>
                    <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
                    <p>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
                    <p>When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>
                    <h3>How we use your information</h3>
                    <p>We use the information we collect in various ways, including to:</p>
                    <p>Provide, operate, and maintain our webste Improve, personalize, and expand our webste Understand and analyze how you use our webste Develop new products, services, features, and functionality Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the webste, and for marketing and promotional purposes Send you emails Find and prevent fraud</p>
                    <h3>Log Files</h3>
                    <p>Naturecallz&nbsp;follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, possibly the number of clicks, search query performed, search query performed, search performed on search engine results page (&ldquo;SERP&rdquo;), search term on SERP, paid advertisements clicked on SERP, views on landing page and clicks on an action button on landing page. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information. No further use will be made nor sharing or selling such data.</p>
                    <h3>Cookies and Web Beacons</h3>
                    <p>Like any other website,&nbsp;Naturecallz&nbsp;uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content&nbsp;Naturecallz&nbsp;on visitors' browser type and/or other information.</p>
                    <h3>Advertising Partners Privacy Policies</h3>
                    <p>You may consult this list to find the Privacy Policy for each of the advertising partners of&nbsp;Naturecallz.</p>
                    <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on&nbsp;Naturecallz, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>
                    <p>Note that&nbsp;Naturecallz&nbsp;has no access to or control over these cookies that are used by third-party advertisers.</p>
                    <h3>Third Party Privacy Policies</h3>
                    <p>Naturecallz's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.</p>
                    <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>
                    <h3>CCPA Privacy Rights (Do Not Sell My Personal Information)</h3>
                    <p>Under the CCPA, among other rights, California consumers have the right to:</p>
                    <p>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</p>
                    <p>Request that a business delete any personal data about the consumer that a business has collected.</p>
                    <p>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</p>
                    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
                    <h3>GDPR Data Protection Rights</h3>
                    <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
                    <p>The right to access &ndash; You have the right to request copies of your personal data. We may charge you a small fee for this service.</p>
                    <p>The right to rectification &ndash; You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</p>
                    <p>The right to erasure &ndash; You have the right to request that we erase your personal data, under certain conditions.</p>
                    <p>The right to restrict processing &ndash; You have the right to request that we restrict the processing of your personal data, under certain conditions.</p>
                    <p>The right to object to processing &ndash; You have the right to object to our processing of your personal data, under certain conditions.</p>
                    <p>The right to data portability &ndash; You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
                    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
                    <h3>Children's Information</h3>
                    <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
                    <p>Naturecallz&nbsp;does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
                    <h3>Contact &amp; data protection officer</h3>
                    <div>In addition, you have the right to complain to a data protection supervisory authority regarding our processing of your personal data.</div>
                    <div>Our Data Protection Officer can be contacted at <a href="mailto:admin@naturecallz.com">admin@naturecallz.com</a></div>
                    <div>&nbsp;</div>
                    <div>EightSphere LTD <br />7 Sapir Street <br />Hertzliya 4685211<br />IL <br />Phone Number: +972 723975627</div>
                    

                    </div>
                </div>
            </div>
        </section>

        {/* footer */}
        <Footer />
    </>
  )
}
