import React from 'react'
import Footer from '../componenets/FooterSearch/Footer'
import Profile from '../componenets/Profile/Profile'

export default function About(props) {
  return (
    <>
        {/* header */}
        <Profile searchCount={props.searchCount} setSearchCount={props.setSearchCount}/>
        <section className="about-tnc-privacy">
            <h1 className='py-5' style={{textAlign: 'center'}}>About Us</h1>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>NatureCallz was created in order to make your search valuable not only for your own needs but also for planet earth.</p>
                        <p>By searching with NatureCallz your search will be translated to do good for our planet.</p>
                        <p>Some of the revenues will be shared with oragnizations related to the cause of preserving our planet green and clean.</p>
                    </div>
                </div>
            </div>
        </section>

        {/* footer */}
        <Footer />
    </>
  )
}
