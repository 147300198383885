import React from 'react'
import Footer from '../componenets/FooterSearch/Footer'
import Profile from '../componenets/Profile/Profile'

export default function Contact(props) {
  return (
    <>
        {/* header */}
        <Profile searchCount={props.searchCount} setSearchCount={props.setSearchCount}/>
        <section className="about-tnc-privacy">
            <h1 className='py-5' style={{textAlign: 'center'}}>Contact Us</h1>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Please feel free to contact us anytime on any query or questions
                                you might have. We will answer as soon as possible</p>
                        <p>support@naturecallz.com</p>
                    </div>
                </div>
            </div>
        </section>

        {/* footer */}
        <Footer />
    </>
  )
}
